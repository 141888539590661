<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col cols="11" sm="7" md="6" lg="5" class="ma-0 pa-0 mh-vh relative d-flex align-center">
      <v-col class="ma-0 pa-0">
        <p class="ma-0 pb-2 fz-32 font-weight-bold text-black ff">Sign in</p>
        <p class="fz-16 text-gray font-weight-medium ff">Sign in to your account using Google</p>
        <v-btn :href='baseApiUrl + "/accounts/login/google-oauth2/?next=" + url' block outlined class="text-none mt-11 rounded-lg text-black font-weight-medium ff"><v-icon class="mr-3">$googleLogo</v-icon>Sign in with Google</v-btn>
<!--      https://marketing-desk.ddns.net/backend/accounts/login/google-oauth2/?next=https://marketing-desk.ddns.net/backend/design/-->
      </v-col>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import gql from 'graphql-tag'
import { baseApiUrl } from '@/constants/config'

export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    async login () {
      await this.$apollo.mutate({
        mutation: gql`mutation log($username: String!, $password: String!) {
          auth {
            login(username: $username, password: $password) {
              id
              email
              fullName
            }
          }
        }`,
        // Parameters
        variables: {
          username: this.username,
          password: this.password
        }
      }).then((data) => {
        if (data.data.auth.login) {
          setTimeout(() => {
            this.$router.push('/design')
          }, 0)
        } else {
          console.log('login or password is incorrect')
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  computed: {
    baseApiUrl () {
      return baseApiUrl
    },
    url () {
      return window.location.origin
    }
  }
}
</script>

<style scoped>

</style>
