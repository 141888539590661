<template>
  <v-app>
    <v-row class="ma-0">
      <v-col cols="12" md="8">
        <router-view/>
      </v-col>
      <v-col cols="0" md="4" class="login-info align-center" :style="{'background': 'url(' + require('@/assets/img/login-bg.jpg') + ')'}">
        <v-row class="ma-0">
          <v-col>
            <v-img contain max-height="30vh" :src="require('@/assets/img/dashboard-features.png')"></v-img>
            <h2 class="fz-24 font-weight-bold text-center mt-7 ff">Dashboard features</h2>
            <v-divider class="mt-4 mb-8"></v-divider>
            <p class="text--secondary text-center ff"><v-icon class="mr-2">$dbFeatures</v-icon>Unlimited tasks and projects</p>
            <p class="text--secondary text-center ff"><v-icon class="mr-2">$dbFeatures</v-icon>Unlimited storage</p>
            <p class="text--secondary text-center ff"><v-icon class="mr-2">$dbFeatures</v-icon>List, Board, and Calendar views</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>

export default {
}
</script>

<style scoped>
</style>
