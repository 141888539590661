<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col cols="5" class="ma-0 pa-0 pb-16 mh-vh relative d-flex align-center">
      <v-col class="ma-0 pa-0">
        <p class="ma-0 pb-2 fz-32 font-weight-bold text-black ff">Login for Localhost</p>
        <p class="fz-16 text-gray mb-10 font-weight-medium ff">Login Localhost</p>
        <v-form>
          <v-col class="pa-0">
            <p class="fz-14 text-dgray mb-1 ff">
              Username
            </p>
            <v-text-field
              dense
              v-model="username"
              outlined
              required
              hide-details
              class="mb-4 rounded-lg ff input-border"
            ></v-text-field>
            <p class="fz-14 text-dgray mb-1 ff">
              Password
            </p>
            <v-text-field
              dense
              v-model="password"
              outlined
              required
              type="password"
              hide-details
              class="rounded-lg ff input-border"
            ></v-text-field>
            <p> {{ console }}</p>
            <router-link class="float-right mt-1 ff text-decoration-underline" to="forgot-password">Forgot your password?</router-link>
            <v-btn @click="login" elevation="0" class="mt-12 rounded-lg ff text-none fz-16" block color="primary">Log In</v-btn>
          </v-col>
        </v-form>
      </v-col>
    </v-col>
    <v-spacer></v-spacer>
  </v-row>
</template>

<script>
import gql from 'graphql-tag'

export default {
  data () {
    return {
      username: '',
      password: '',
      console: ''
    }
  },
  methods: {
    async login () {
      this.console = 'test'
      await this.$apollo.mutate({
        mutation: gql`mutation log($username: String!, $password: String!) {
          auth {
            login(username: $username, password: $password) {
              id
              email
              fullName
            }
          }
        }`,
        // Parameters
        variables: {
          username: this.username,
          password: this.password
        }
      }).then((data) => {
        console.log(data.data.auth.login)
        this.console = data.data
        if (data.data.auth.login) {
          setTimeout(() => {
            this.$router.push('/plans')
          }, 0)
        } else {
          console.log('login or password is incorrect')
        }
      }).catch((err) => {
        this.console = err
        console.log(err)
      })
    }
  }
}

</script>

<style scoped>

</style>
